<template>
  <div class="container mt-5">
    <div class="col-lg-12 card" style="padding-top:17px;margin-bottom:15px;padding-right:15px;padding-bottom:8px;">
      <div class="d-flex justify-content-between align-items-center">
        <div class="mt-4">
          <button @click="openModal" class="btn btn-secondary mx-1">افزودن تخفیف</button>
        </div>
      </div>

      <div v-if="discounts.length > 0" class="table-responsive mt-3">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">کد تخفیف</th>
              <th scope="col">تاریخ ایجاد</th>
              <th scope="col">تاریخ انقضاء</th>
              <th scope="col">شناسه محصول</th>
              <th scope="col">نام محصول</th>
              <th scope="col">تعداد تخفیف‌ها</th>
              <th scope="col">درصد تخفیف</th>
              <th scope="col">مقدار تومانی</th>
              <th scope="col">مقدار یورویی</th>
              <th scope="col">عملیات</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="discount in discounts" :key="discount.discountCode">
              <td>{{ discount.discountCode }}</td>
              <td>{{ discount.created_at }}</td>
              <td>{{ discount.to_date_hour }}</td>
              <td>{{ discount.product_id }}</td>
              <td>{{ getProductName(discount.product_id) }}</td>
              <td>{{ discount.num_of_discounts }}</td>
              <td>{{ discount.percentage }}</td>
              <td>{{ discount.amount }}</td>
              <td>{{ discount.amount_euro }}</td>
              <td>
                <button class="btn btn-info btn-sm" @click="openEditModal(discount)">تغییر</button>
                <button class="btn btn-info btn-sm" style="margin-right:5px;" @click="confirmDelete(discount.discountCode)">حذف</button> <!-- Delete button -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    <div v-if="error" class="alert alert-danger mt-3">
      {{ error }}
    </div>

<!-- Delete Confirmation Modal -->
<div class="modal fade" id="deleteDiscountModal" tabindex="-1" aria-labelledby="deleteDiscountModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteDiscountModalLabel">حذف تخفیف</h5>
              <button type="button" class="btn-close" @click="closeDeleteModal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              آیا از حذف این تخفیف مطمئن هستید؟
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeDeleteModal">انصراف</button>
              <button type="button" class="btn btn-danger" @click="deleteDiscount">حذف</button>
            </div>
          </div>
        </div>
      </div>

    <!-- Add Discount Modal -->
    <div class="modal fade" id="addDiscountModal" tabindex="-1" aria-labelledby="addDiscountModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addDiscountModalLabel">افزودن تخفیف</h5>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="discountCode" class="form-label">کد تخفیف</label>
              <input type="text" v-model="newDiscount.discountCode" class="form-control" id="discountCode" required>
            </div>
            <div class="mb-3">
              <label for="toDateHour" class="form-label">تاریخ انقضاء</label>
              <input type="datetime-local" v-model="newDiscount.to_date_hour" class="form-control" id="toDateHour" required>
            </div>
            <div class="mb-3">
              <label for="productId" class="form-label">شناسه محصول</label>
              <select v-model="newDiscount.product_id" class="form-control" id="productId" required>
                <option v-for="webinar in webinars" :key="webinar.id" :value="webinar.id">
                  {{ webinar.title + " - بار " + webinar.nth_course }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="numOfDiscounts" class="form-label">تعداد تخفیف‌ها</label>
              <input type="number" v-model="newDiscount.num_of_discounts" class="form-control" id="numOfDiscounts" required>
            </div>
            <div class="mb-3">
              <label for="percentage" class="form-label">درصد تخفیف</label>
              <input type="number" step="0.01" v-model="newDiscount.percentage" class="form-control" id="percentage" required>
            </div>

            <div class="mb-3">
              <label for="amount" class="form-label">مقدار تومانی</label> <!-- Updated line -->
              <input type="number" v-model="newDiscount.amount" class="form-control" id="amount" required> <!-- Updated line -->
            </div>

            <div class="mb-3">
              <label for="amountEuro" class="form-label">مقدار یورویی</label> <!-- Add this line -->
              <input type="number" v-model="newDiscount.amount_euro" class="form-control" id="amountEuro" required> <!-- Add this line -->
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="addDiscount">افزودن تخفیف</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Discount Modal --> <!-- Add this section -->
    <div class="modal fade" id="editDiscountModal" tabindex="-1" aria-labelledby="editDiscountModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editDiscountModalLabel">ویرایش تخفیف</h5>
            <button type="button" class="btn-close" @click="closeEditModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="editDiscountCode" class="form-label">کد تخفیف</label>
              <input type="text" v-model="currentDiscount.discountCode" class="form-control" id="editDiscountCode" disabled>
            </div>
            <div class="mb-3">
              <label for="editToDateHour" class="form-label">تاریخ انقضاء</label>
              <input type="datetime-local" v-model="currentDiscount.to_date_hour" class="form-control" id="editToDateHour" required>
            </div>
            <div class="mb-3">
              <label for="editProductId" class="form-label">شناسه محصول</label>
              <select v-model="currentDiscount.product_id" class="form-control" id="editProductId" required>
                <option v-for="webinar in webinars" :key="webinar.id" :value="webinar.id">
                  {{ webinar.title }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="editNumOfDiscounts" class="form-label">تعداد تخفیف‌ها</label>
              <input type="number" v-model="currentDiscount.num_of_discounts" class="form-control" id="editNumOfDiscounts" required>
            </div>
            <div class="mb-3">
              <label for="editPercentage" class="form-label">درصد تخفیف</label>
              <input type="number" step="0.01" v-model="currentDiscount.percentage" class="form-control" id="editPercentage" required>
            </div>


            <div class="mb-3">
              <label for="editAmount" class="form-label">مقدار تومانی</label> <!-- Updated line -->
              <input type="number" v-model="currentDiscount.amount" class="form-control" id="editAmount" required> <!-- Updated line -->

            </div>

            <div class="mb-3">
              <label for="editAmountEuro" class="form-label">مقدار یورویی</label> <!-- Add this line -->
              <input type="number" v-model="currentDiscount.amount_euro" class="form-control" id="editAmountEuro" required> <!-- Add this line -->
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeEditModal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="updateDiscount">ذخیره تغییرات</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Toast -->
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="copyToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">عملیات</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ toastMessage }}
        </div>
      </div>
    </div>


    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Modal, Toast } from 'bootstrap';

export default {
  data() {
    return {
      discounts: [],
      webinars: [],
      error: '',
      toastMessage: '',
      toastInstance: null,
      newDiscount: {
        discountCode: '',
        to_date_hour: '',
        product_id: '',
        num_of_discounts: '',
        percentage: '',
        amount: '', // Updated line
        amount_euro: '' // Add this line
      },
      currentDiscount: { // Add this object
        discountCode: '',
        to_date_hour: '',
        product_id: '',
        num_of_discounts: '',
        percentage: '',
              
        amount: '', // Updated line
        amount_euro: '' // Add this line
      },
      deleteDiscountCode: '', // Store the discount code to be deleted
      deleteModalInstance: null, // For delete modal instance
      modalInstance: null,
      editModalInstance: null // Add this line
    };
  },
  methods: {
    fetchDiscounts() {
      const token = localStorage.getItem('jwt');
      console.log('Fetching discounts...');
      axios.post('https://dashboard.ordmenpodcast.com/v/list_discounts.php', {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        console.log('Discounts fetched:', response.data);
        this.discounts = response.data;
      })
      .catch(error => {
        console.error('Error fetching discounts:', error);
        this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
      });
    },
    fetchWebinars() {
      const token = localStorage.getItem('jwt');
      console.log('Fetching webinars...');
      axios.get('https://dashboard.ordmenpodcast.com/v/list_webinars.php', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        console.log('Webinars fetched:', response.data);
        this.webinars = response.data.webinars;
      })
      .catch(error => {
        console.error('Error fetching webinars:', error);
        this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
        window.location.href = '/logout';
      });
    },
    getProductName(productId) {
      const webinar = this.webinars.find(webinar => webinar.id === productId);
      return webinar ? webinar.title + " - بار " + webinar.nth_course : '';
    },
    addDiscount() {
      const token = localStorage.getItem('jwt');
      console.log('Adding new discount...');
      axios.post('https://dashboard.ordmenpodcast.com/v/add_discount.php', this.newDiscount, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        console.log('Discount added:', response.data);
        if (response.data.success) {
          this.fetchDiscounts(); // Refresh the discount list
          this.newDiscount = {
            discountCode: '',
            to_date_hour: '',
            product_id: '',
            num_of_discounts: '',
            percentage: '',
            amount: '' // Add this line
          };
          this.closeModal();
          this.toastMessage = 'تخفیف با موفقیت اضافه شد!';
          this.showToast();
        } else {
          this.error = response.data.error || 'Failed to add discount';
        }
      })
      .catch(error => {
        console.error('Error adding discount:', error);
        this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
      });
    },updateDiscount() {
  const token = localStorage.getItem('jwt');
  console.log('Updating discount...');
  const { discountCode, ...updateData } = this.currentDiscount; // Exclude created_at from update

  // Add the update_or_delete parameter to specify the operation type
  axios.post('https://dashboard.ordmenpodcast.com/v/update_discount.php', { 
      ...updateData, 
      discountCode, 
      delete_or_update: 'update'  // Add this parameter
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      console.log('Discount updated:', response.data);
      if (response.data.success) {
        this.fetchDiscounts(); // Refresh the discount list
        this.closeEditModal();
        this.toastMessage = 'تخفیف با موفقیت ویرایش شد!';
        this.showToast();
      } else {
        this.error = response.data.error || 'Failed to update discount';
      }
    })
    .catch(error => {
      console.error('Error updating discount:', error);
      this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
    });
},

    openModal() {
      const modalElement = document.getElementById('addDiscountModal');
      this.modalInstance = new Modal(modalElement);
      this.modalInstance.show();
    },
    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },
    openEditModal(discount) { // Add this method
      this.currentDiscount = { ...discount };
      const modalElement = document.getElementById('editDiscountModal');
      this.editModalInstance = new Modal(modalElement);
      this.editModalInstance.show();
    },
    closeEditModal() { // Add this method
      if (this.editModalInstance) {
        this.editModalInstance.hide();
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Copied to clipboard:', text);
        this.toastMessage = 'به کلیپ‌بورد کپی شد!';
        this.showToast();
      }).catch(err => {
        console.error('Failed to copy:', err);
      });
    },
    showToast() {
      const toastElement = document.getElementById('copyToast');
      this.toastInstance = new Toast(toastElement);
      this.toastInstance.show();
    },
    confirmDelete(discountCode) {
      this.deleteDiscountCode = discountCode;
      const modalElement = document.getElementById('deleteDiscountModal');
      this.deleteModalInstance = new Modal(modalElement);
      this.deleteModalInstance.show();
    },
    deleteDiscount() {
      const token = localStorage.getItem('jwt');
      axios.post('https://dashboard.ordmenpodcast.com/v/update_discount.php', {
        discountCode: this.deleteDiscountCode,
        delete_or_update: 'delete'
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.success) {
          this.fetchDiscounts(); // Refresh the discount list
          this.toastMessage = 'تخفیف با موفقیت حذف شد!';
          this.showToast();
        } else {
          this.error = response.data.error || 'Failed to delete discount';
        }
        this.closeDeleteModal(); // Close delete modal after success
      })
      .catch(error => {
        console.error('Error deleting discount:', error);
        this.error = error.response ? error.response.data.error : 'خطا در حذف تخفیف';
      });
    },
    closeDeleteModal() {
      if (this.deleteModalInstance) {
        this.deleteModalInstance.hide();
      }
    }
  },
  mounted() {
    this.fetchDiscounts();
    this.fetchWebinars();
  }
};
</script>


<style scoped>
/* Custom styles for the toast */
.toast-container {
  z-index: 1060; /* Ensure the toast is above other elements */
}

.container{
    width: 100% !important;
    max-width: 100% !important;
    font-size:12.5px!important;
  }

</style>
