<template>
  <div class="container mt-5">
    <h2>لیست سوالات مشاوره</h2>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">نام</th>
            <th scope="col">تخصص</th>
            <th scope="col">سوال</th>
            <th scope="col">وضعیت</th>
            <th scope="col">عملیات</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="question in questions" :key="question.id">
            <td>{{ question.name }}</td>
            <td>{{ question.expert }}</td>
            <td>
              <button class="btn btn-info" @click="showQuestionContent(question.content)">
                نمایش سوال
              </button>
            </td>
            <td>{{ translateStatus(question.status) }}</td>
            <td>
              <button class="btn btn-success" @click="changeStatus(question.id, 'published')" style="margin-left:5px;" v-if="question.status === 'pending'">
                انتشار
              </button>
              <button class="btn btn-danger" @click="changeStatus(question.id, 'rejected')" v-if="question.status === 'pending'">
                ردکردن
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal to display the question content -->
    <div class="modal fade" id="questionModal" tabindex="-1" role="dialog" aria-labelledby="questionModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="questionModalLabel">محتوای سوال</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{ modalContent }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">بستن</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';

export default {
  data() {
    return {
      questions: [],
      modalContent: '',
    };
  },
  methods: {
    fetchQuestions() {
      const token = localStorage.getItem('jwt');
      axios
        .post('https://dashboard.ordmenpodcast.com/v/get_mentorship_questions.php', {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.questions = response.data.data;
          } else {
            console.error('Error fetching questions:', response.data.error);
          }
        })
        .catch((error) => {
          console.error('Error fetching questions:', error);
        });
    },
    showQuestionContent(content) {
      this.modalContent = content;
      const modalElement = document.getElementById('questionModal');
      const modal = new Modal(modalElement);
      modal.show();
    },
    translateStatus(status) {
      switch (status) {
        case 'pending':
          return 'در انتظار بررسی';
        case 'rejected':
          return 'رد شده';
        case 'published':
          return 'منتشر شده';
        default:
          return '';
      }
    },
    changeStatus(questionId, status) {
      const token = localStorage.getItem('jwt');
      axios
        .post('https://dashboard.ordmenpodcast.com/v/change_status_question.php', {
          question_id: questionId,
          status: status,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.fetchQuestions(); // Refresh the list after status change
          } else {
            console.error('Error changing status:', response.data.error);
          }
        })
        .catch((error) => {
          console.error('Error changing status:', error);
          window.location.href = '/logout';
        });
    },
  },
  mounted() {
    this.fetchQuestions();
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin-top: 50px;
}
</style>
