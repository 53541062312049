<template>
  <div class="container mt-5">

        <div class="col-lg-12" style="margin-bottom:25px;">
          <div class="card" style="padding:10px 15px;border-radius:50px;">
            <div style="float:right;width:100%;">
                <div style="float:right;width:40%">
                  <button class="btn btn-primaryy" style="float:Right;background:orange" @click="openNewMentorshipModal">
  اضافه کردن مشاوره
</button>
                  </div> 


               <div style="float:left;width:20%">
              

        <input
          type="text"
          v-model="searchQuery"
          placeholder="جستجو: نام، ایمیل"
          class="form-control form-search"
        />

                </div>
              </div>
          </div>
        </div>





    <div class="row">
      <div class="col-md-9 card" style="padding-top:17px;margin-bottom:15px;">
        <div v-if="mentorships.length > 0" class="table-responsive mt-3" style="width: 100%; table-layout: auto; max-height: 650px; overflow-y: auto;">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">ردیف</th> <!-- New Index Column Header -->
                <th scope="col">تغییر</th>

                <th scope="col">ایمیل</th>
                <th scope="col">نام</th>
                <th scope="col">
  مشاوره چندم؟
  <button @click="toggleSort('mentorshipOrder')" class="btn btn-link btn-sm">
    <svg v-if="sortOrder === 'asc' && sortKey === 'mentorshipOrder'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-down">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <line x1="12" y1="5" x2="12" y2="19"/>
      <line x1="18" y1="13" x2="12" y2="19"/>
      <line x1="6" y1="13" x2="12" y2="19"/>
    </svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-up">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <line x1="12" y1="5" x2="12" y2="19"/>
      <line x1="18" y1="11" x2="12" y2="5"/>
      <line x1="6" y1="11" x2="12" y2="5"/>
    </svg>
  </button>
</th>
<th scope="col">
  تاریخ
  <button @click="toggleSort('date')" class="btn btn-link btn-sm">
    <svg v-if="sortOrder === 'asc' && sortKey === 'date'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-down">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <line x1="12" y1="5" x2="12" y2="19"/>
      <line x1="18" y1="13" x2="12" y2="19"/>
      <line x1="6" y1="13" x2="12" y2="19"/>
    </svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-up">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <line x1="12" y1="5" x2="12" y2="19"/>
      <line x1="18" y1="11" x2="12" y2="5"/>
      <line x1="6" y1="11" x2="12" y2="5"/>
    </svg>
  </button>
</th>
                <th scope="col">ساعت</th>
                <th scope="col">منطقه زمانی</th>
                <th scope="col">حوزه مشاوره</th>
                <th scope="col">توضیحات مشاوره</th>
                <th scope="col">خارج از کشور</th>
                <th scope="col">ارسال ایمیل</th>
                <th scope="col">ارسال تلگرام</th>
                <th scope="col">یادداشت</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(mentorship, index) in filteredMentorships" :key="mentorship.hashmd5">
                <td>{{ index + 1 }}</td> <!-- New Index Column Data -->
    <td>
    
      <button @click="showEditMentorshipModal(mentorship)" class="btn btn-warning btn-sm mx-1" style="margin-bottom:3px;" >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
      <path d="M13.498.795l.149-.149a1.207 1.207 0 1 1 1.707 1.707l-.149.149a.5.5 0 0 1-.034.034l-10 10a.5.5 0 0 1-.168.11l-4 1a.5.5 0 0 1-.621-.621l1-4a.5.5 0 0 1 .11-.168l10-10a.5.5 0 0 1 .034-.034zm-1.94 1.757l-9.742 9.743-.691 2.764 2.764-.691 9.742-9.742-2.073-2.074z"/>
    </svg>
  </button>

  <!-- Delete button -->
  <button @click="showDeleteMentorshipModal(mentorship.hashmd5)" class="btn btn-warning btn-sm mx-1" >
    
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
      <path d="M5.5 5.5A.5.5 0 0 1 6 5h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5H6a.5.5 0 0 1-.5-.5v-7zM4.118 4 4 4.059V13.5a1.5 1.5 0 0 0 1.5 1.5h5A1.5 1.5 0 0 0 12 13.5V4.059l-.118-.059H4.118zM2.5 3h11a.5.5 0 0 1 .5.5v.5H2v-.5a.5.5 0 0 1 .5-.5zM7.5 1.5A1.5 1.5 0 0 1 9 3h-2a1.5 1.5 0 0 1 1.5-1.5z"/>
    </svg>
  </button>

    </td>
                <td>{{ mentorship.email }}</td>
                <td>{{ mentorship.name }}</td>
<td>{{ mentorshipOrder[mentorship.hashmd5] }}</td>
                <td>{{ mentorship.date }}</td>
                <td>{{ mentorship.hour }}</td>
                <td>{{ mentorship.timezonename }}</td>
                <td>{{ mentorship.mentorship_area }}</td>
                <td>
                  <button class="btn btn-info btn-sm" @click="showMentorshipDescription(mentorship.mentorship_description)">
                    نمایش
                  </button>
                </td>
                <td>{{ getYesNo(mentorship.abroad) }}</td>
                <td>
                  <button class="btn btn-secondary btn-sm" @click="showCustomEmailModal(mentorship.email)">
                    ارسال ایمیل
                  </button>
                </td>
                <td>
                  <button class="btn btn-secondary btn-sm" @click="showCustomTelegramModal(mentorship.email)">
                    ارسال تلگرام
                  </button>
                </td>
                <td>
                  <button class="btn btn-info btn-sm" @click="showMentorshipNoteModal(mentorship.note)">
                    ℹ️
                  </button>
                  <button class="btn btn-warning btn-sm" @click="editMentorshipNoteModal(mentorship.hashmd5, mentorship.note)">
                    ✏️
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="error" class="alert alert-danger mt-3">
          {{ error }}
        </div>
      </div>
      <div class="col-md-3">
        <!-- Stats and Filters Tabs -->
        <div class="card mt-4 mt-md-0">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs" role="tablist">
              <li class="nav-item" role="presentation">
                <a href="#tabs-stats" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">مشاوره‌ها</a>
              </li>
              <li class="nav-item" role="presentation">
                <a href="#tabs-monthly-mentorships" class="nav-link" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">مشاوره‌های ماه</a>
              </li>
              <li class="nav-item" role="presentation">
                <a href="#tabs-filters" class="nav-link" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">فیلترها</a>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <div class="tab-content">
              <div class="tab-pane fade active show" id="tabs-stats" role="tabpanel">
                <div class="mt-4">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>ویژگی</th>
                        <th class="centeredtext">مقدار</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>تعداد کل مشاوره ها</td>
                        <td class="centeredtext">{{ filteredMentorships.length }}</td>
                      </tr>
                      <tr>
                        <td>تعداد خارجی‌ها</td>
                        <td class="centeredtext">{{ totalAbroad }}</td>
                      </tr>
                      <tr>
                        <td>تعداد داخلی‌ها</td>
                        <td class="centeredtext">{{ totalInside }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              <!-- New Tab for Monthly Mentorships -->
              <div class="tab-pane fade" id="tabs-monthly-mentorships" role="tabpanel">
                <div class="mb-3">
                  <label for="monthSelectMentorship" class="form-label">انتخاب ماه:</label>
                  <select v-model="selectedMentorshipMonth" @change="updateMonthlyMentorshipStats" id="monthSelectMentorship" class="form-select">
                    <option value="01">فروردین</option>
                    <option value="02">اردیبهشت</option>
                    <option value="03">خرداد</option>
                    <option value="04">تیر</option>
                    <option value="05">مرداد</option>
                    <option value="06">شهریور</option>
                    <option value="07">مهر</option>
                    <option value="08">آبان</option>
                    <option value="09">آذر</option>
                    <option value="10">دی</option>
                    <option value="11">بهمن</option>
                    <option value="12">اسفند</option>
                  </select>
                </div>
                <div class="mt-4">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>ویژگی</th>
                        <th class="centeredtext">مقدار</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>تعداد کل مشاوره‌ها</td>
                        <td class="centeredtext">{{ monthlyMentorshipStats.total }}</td>
                      </tr>
                      <tr>
                        <td>تعداد خارجی‌ها</td>
                        <td class="centeredtext">{{ monthlyMentorshipStats.abroad }}</td>
                      </tr>
                      <tr>
                        <td>تعداد داخلی‌ها</td>
                        <td class="centeredtext">{{ monthlyMentorshipStats.inside }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- End of New Tab for Monthly Mentorships -->

              <div class="tab-pane fade" id="tabs-filters" role="tabpanel">
                <div class="mt-4">
                  <select v-model="filterAbroad" class="form-control mb-2">
                    <option value="">موقعیت جغرافیایی: همه</option>
                    <option value="1">خارج</option>
                    <option value="0">داخل</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End of Stats and Filters Tabs -->
      </div>
    </div>



<!-- Edit Mentorship Modal -->
<div class="modal fade" id="editMentorshipModal" tabindex="-1" aria-labelledby="editMentorshipModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editMentorshipModalLabel">ویرایش مشاوره</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="submitEditMentorship">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label for="editEmail" class="form-label">ایمیل <span class="text-danger">*</span></label>
                <input type="email" class="form-control" id="editEmail" v-model="editMentorship.email" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="editName" class="form-label">نام <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="editName" v-model="editMentorship.name" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="editDate" class="form-label">تاریخ <span class="text-danger">*</span></label>
                <input type="date" class="form-control" id="editDate" v-model="editMentorship.date" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="editHour" class="form-label">ساعت <span class="text-danger">*</span></label>
                <input type="time" class="form-control" id="editHour" v-model="editMentorship.hour" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="editTimezone" class="form-label">منطقه زمانی <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="editTimezone" v-model="editMentorship.timezonename" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="editAbroad" class="form-label">خارجی</label>
                <select class="form-select" id="editAbroad" v-model="editMentorship.abroad">
                  <option value="0">خیر</option>
                  <option value="1">بله</option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label for="editMentorshipArea" class="form-label">حوزه مشاوره</label>
                <input type="text" class="form-control" id="editMentorshipArea" v-model="editMentorship.mentorship_area">
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label for="editMentorshipDescription" class="form-label">توضیحات مشاوره</label>
                <textarea class="form-control" id="editMentorshipDescription" v-model="editMentorship.mentorship_description"></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <button type="submit" class="btn btn-primary">ذخیره</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Delete Confirmation Modal -->
<div class="modal fade" id="deleteMentorshipModal" tabindex="-1" aria-labelledby="deleteMentorshipModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteMentorshipModalLabel">حذف مشاوره</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        آیا مطمئن هستید که می‌خواهید این مشاوره را حذف کنید؟
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">انصراف</button>
        <button type="button" class="btn btn-danger" @click="confirmDeleteMentorship">حذف</button>
      </div>
    </div>
  </div>
</div>


<!-- New Mentorship Modal -->
<div class="modal fade" id="newMentorshipModal" tabindex="-1" aria-labelledby="newMentorshipModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newMentorshipModalLabel">اضافه کردن مشاوره</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="submitNewMentorship">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label for="email" class="form-label">
                  ایمیل <span class="text-danger">*</span>
                </label>
                <input type="email" class="form-control" id="email" v-model="newMentorship.email" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="name" class="form-label">
                  نام <span class="text-danger">*</span>
                </label>
                <input type="text" class="form-control" id="name" v-model="newMentorship.name" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="date" class="form-label">
                  تاریخ <span class="text-danger">*</span>
                </label>
                <input type="date" class="form-control" id="date" v-model="newMentorship.date" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="hour" class="form-label">
                  ساعت <span class="text-danger">*</span>
                </label>
                <input type="time" class="form-control" id="hour" v-model="newMentorship.hour" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="timezonename" class="form-label">
                  منطقه زمانی <span class="text-danger">*</span>
                </label>
                <input type="text" class="form-control" id="timezonename" v-model="newMentorship.timezonename" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="abroad" class="form-label">خارجی</label>
                <select class="form-select" id="abroad" v-model="newMentorship.abroad">
                  <option value="0">خیر</option>
                  <option value="1">بله</option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label for="mentorship_area" class="form-label">حوزه مشاوره</label>
                <input type="text" class="form-control" id="mentorship_area" v-model="newMentorship.mentorship_area">
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label for="mentorship_description" class="form-label">توضیحات مشاوره</label>
                <textarea class="form-control" id="mentorship_description" v-model="newMentorship.mentorship_description"></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <button type="submit" class="btn btn-primary">ثبت</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

    <!-- Modals and Toasts -->
    <div class="modal fade" id="mentorshipDescriptionModal" tabindex="-1" role="dialog" aria-labelledby="mentorshipDescriptionModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="mentorshipDescriptionModalLabel">توضیحات مشاوره</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p v-html="formatTextAsHtml(currentMentorshipDescription)"></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">بستن</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="mentorshipNoteModal" tabindex="-1" role="dialog" aria-labelledby="mentorshipNoteModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="mentorshipNoteModalLabel">یادداشت مشاوره</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p v-html="formatTextAsHtml(currentMentorshipNote)"></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">بستن</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editMentorshipNoteModal" tabindex="-1" role="dialog" aria-labelledby="editMentorshipNoteModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editMentorshipNoteModalLabel">ویرایش یادداشت مشاوره</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="mentorshipNoteContent">محتوا</label>
              <ckeditor :editor="editor" v-model="currentMentorshipNote" :config="editorConfig" class="custom-editor" style="height: 300px; width: 600px;"></ckeditor>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="updateMentorshipNote">ذخیره</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="customEmailModal" tabindex="-1" role="dialog" aria-labelledby="customEmailModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customEmailModalLabel">ارسال ایمیل دلخواه</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="customEmailSubject">موضوع</label>
              <input v-model="customEmailSubject" type="text" class="form-control" id="customEmailSubject" required />
            </div>
            <div class="form-group">
              <label for="customEmailContent">محتوا</label>
              <ckeditor :editor="editor" v-model="customEmailContent" :config="editorConfig" class="custom-editor" style="height: 300px; width: 600px;"></ckeditor>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="sendCustomEmails">ارسال ایمیل</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="customTelegramModal" tabindex="-1" role="dialog" aria-labelledby="customTelegramModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customTelegramModalLabel">ارسال پیام تلگرام دلخواه</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="customTelegramContent">محتوا</label>
              <ckeditor :editor="editor" v-model="customTelegramContent" :config="editorConfig" class="custom-editor" style="min-height: 300px; width: 600px;"></ckeditor>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="sendCustomTelegramMessages">ارسال پیام</button>
          </div>
        </div>
      </div>
    </div>

    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="copyToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">عملیات</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ toastMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Modal, Toast } from 'bootstrap';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { htmlToText } from 'html-to-text';
import jalaali from 'jalaali-js';

export default {
  data() {
    return {
      mentorships: [],
      error: '',
      currentMentorshipDescription: '',
      currentMentorshipNote: '',
      currentMentorshipHash: '',
      customEmailSubject: '',
      customEmailContent: '',
      customTelegramContent: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'ar', // Set the language to Arabic (or any RTL language)
        contentsLangDirection: 'rtl', // Set the content direction to RTL
        alignment: {
          options: ['right', 'left'] // Only allow right alignment
        }
      },
      selectedEmail: '',
      selectedChatID: '',
      toastMessage: '',
      customEmailInstance: null,
      customTelegramInstance: null,
      mentorshipNoteInstance: null,
      searchQuery: '',
      filterAbroad: '',
      newMentorship: {
      email: '',
      name: '',
      date: '',
      hour: '',
      timezonename: '',
      mentorship_area: '',
      mentorship_description: '',
      abroad: 0
    },
      selectedMentorshipMonth: '',
      monthlyMentorshipStats: {
        total: 0,
        abroad: 0,
        inside: 0,
      },
      editMentorship: {
        email: '',
        name: '',
        date: '',
        hour: '',
        timezonename: '',
        mentorship_area: '',
        mentorship_description: '',
        abroad: 0
      },
      selectedMentorshipHash: '',
      sortKey: '',
      sortOrder: ''
    };
  },
  computed: {
    filteredMentorships() {
      return this.mentorships.filter(mentorship => {
        const matchesSearchQuery = mentorship.email.includes(this.searchQuery) || mentorship.name.includes(this.searchQuery);
        const matchesAbroadFilter = this.filterAbroad === '' || mentorship.abroad == this.filterAbroad;
        return matchesSearchQuery && matchesAbroadFilter;
      });
    },
    totalAbroad() {
      return this.filteredMentorships.filter(mentorship => mentorship.abroad == 1).length;
    },
    totalInside() {
      return this.filteredMentorships.filter(mentorship => mentorship.abroad == 0).length;
    },
  mentorshipOrder() {
    let emailCounts = {};
    let order = {};

    this.filteredMentorships.forEach(mentorship => {
      if (!emailCounts[mentorship.email]) {
        emailCounts[mentorship.email] = 0;
      }
      emailCounts[mentorship.email]++;
      order[mentorship.hashmd5] = emailCounts[mentorship.email];
    });

    return order;
  }
  },
  methods: {
    openNewMentorshipModal() {
    const modal = new Modal(document.getElementById('newMentorshipModal'));
    modal.show();
  },
  submitNewMentorship() {
    const token = localStorage.getItem('jwt');
    axios.post('https://dashboard.ordmenpodcast.com/v/add_mentorship.php', this.newMentorship, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(() => {
      this.fetchMentorships(); // Refresh mentorships after adding
      this.newMentorship = {
        email: '',
        name: '',
        date: '',
        hour: '',
        timezonename: '',
        mentorship_area: '',
        mentorship_description: '',
        abroad: 0
      };
      const modal = Modal.getInstance(document.getElementById('newMentorshipModal'));
      modal.hide();
    })
    .catch(error => {
      this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا دوباره تلاش کنید.';
    });
  },
    updateMonthlyMentorshipStats() {
      const selectedMonth = this.selectedMentorshipMonth;
      this.monthlyMentorshipStats.total = 0;
      this.monthlyMentorshipStats.abroad = 0;
      this.monthlyMentorshipStats.inside = 0;

      this.mentorships.forEach(mentorship => {
        const { jm } = jalaali.toJalaali(new Date(mentorship.date));
        if (jm === parseInt(selectedMonth, 10)) {
          this.monthlyMentorshipStats.total++;
          if (mentorship.abroad === '1') {
            this.monthlyMentorshipStats.abroad++;
          } else {
            this.monthlyMentorshipStats.inside++;
          }
        }
      });
    },
    setDefaultMentorshipMonth() {
      const now = new Date();
      const { jm } = jalaali.toJalaali(now);
      const month = jm.toString().padStart(2, '0');
      this.selectedMentorshipMonth = month;
      this.updateMonthlyMentorshipStats();
    },
    fetchMentorships() {
      const token = localStorage.getItem('jwt');
      axios.post('https://dashboard.ordmenpodcast.com/v/mentorships_list.php', {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          this.mentorships = response.data;
        })
        .catch(error => {
          this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
          window.location.href = '/logout';
        });
    },
    getYesNo(value) {
      return value === '1' ? 'بله' : 'خیر';
    },
    showMentorshipDescription(description) {
      this.currentMentorshipDescription = description;
      const modalElement = document.getElementById('mentorshipDescriptionModal');
      const modal = new Modal(modalElement);
      modal.show();
    },
    showMentorshipNoteModal(note) {
      this.currentMentorshipNote = note;
      const modalElement = document.getElementById('mentorshipNoteModal');
      const modal = new Modal(modalElement);
      modal.show();
    },
    editMentorshipNoteModal(hashmd5, note) {
      this.currentMentorshipHash = hashmd5;
      this.currentMentorshipNote = note;
      const modalElement = document.getElementById('editMentorshipNoteModal');
      this.mentorshipNoteInstance = new Modal(modalElement);
      this.mentorshipNoteInstance.show();
    },
    updateMentorshipNote() {
      const token = localStorage.getItem('jwt');
      axios.post('https://dashboard.ordmenpodcast.com/v/update_mentorship_note.php', {
        hashmd5: this.currentMentorshipHash,
        note: this.currentMentorshipNote
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.data.success) {
            const mentorship = this.mentorships.find(m => m.hashmd5 === this.currentMentorshipHash);
            if (mentorship) {
              mentorship.note = this.currentMentorshipNote;
            }
            this.toastMessage = 'یادداشت با موفقیت به‌روزرسانی شد!';
            this.showToast();
            this.mentorshipNoteInstance.hide();
          } else {
            this.error = response.data.error || 'Failed to update note';
          }
        })
        .catch(error => {
          this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
        });
    },
    showCustomEmailModal(email) {
      this.selectedEmail = email;
      const modalElement = document.getElementById('customEmailModal');
      this.customEmailInstance = new Modal(modalElement);
      this.customEmailInstance.show();
    },
    hideCustomEmailModal() {
      if (this.customEmailInstance) {
        this.customEmailInstance.hide();
      }
    },
    sendCustomEmails() {
      const token = localStorage.getItem('jwt');
      const emailContent = `
        <div style='text-align:right!important;direction:rtl!important;font-family:Tahoma!important;font-size:13px;'>
        ${this.customEmailContent}
        </div>
      `;
      axios.post('https://dashboard.ordmenpodcast.com/v/send_email.php', {
        email: this.selectedEmail,
        subject: this.customEmailSubject,
        content: emailContent
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.data.success) {
            this.toastMessage = 'ایمیل با موفقیت ارسال شد!';
            this.showToast();
            this.hideCustomEmailModal();
          } else {
            this.error = response.data.error || 'Failed to send email';
          }
        })
        .catch(error => {
          this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
        });
    },
    showCustomTelegramModal(email) {
      this.selectedChatID = email;
      const modalElement = document.getElementById('customTelegramModal');
      this.customTelegramInstance = new Modal(modalElement);
      this.customTelegramInstance.show();
    },
    hideCustomTelegramModal() {
      if (this.customTelegramInstance) {
        this.customTelegramInstance.hide();
      }
    },
    sendCustomTelegramMessages() {
      const token = localStorage.getItem('jwt');
      const telegramMessage = htmlToText(this.customTelegramContent, {
        wordwrap: 130,
        uppercaseHeadings: false,
        singleNewLineParagraphs: true,
        tags: {
          'a': { format: 'inline' },
          'p': { format: 'block' }
        }
      });
      axios.post('https://dashboard.ordmenpodcast.com/v/send_telegram_using_email_message.php', {
        email: this.selectedChatID,
        text: telegramMessage,
        title: "پیام ارسالی از طریق صفحه مشاوره"
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.data.success) {
            this.toastMessage = 'پیام تلگرام با موفقیت ارسال شد!';
            this.showToast();
            this.hideCustomTelegramModal();
          } else {
            this.error = response.data.error || 'Failed to send Telegram message';
          }
        })
        .catch(error => {
          this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
        });
    },
    showToast() {
      const toastElement = document.getElementById('copyToast');
      this.toastInstance = new Toast(toastElement);
      this.toastInstance.show();
    },
    formatTextAsHtml(text) {
      if (!text) return '';
      return text.replace(/\n/g, '<br>');
    },
    showEditMentorshipModal(mentorship) {
      this.editMentorship = { ...mentorship };
      const modal = new Modal(document.getElementById('editMentorshipModal'));
      modal.show();
    },
    submitEditMentorship() {
      const token = localStorage.getItem('jwt');
      axios.post('https://dashboard.ordmenpodcast.com/v/change_mentorship.php', {
        hashmd5: this.editMentorship.hashmd5,
        ...this.editMentorship
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(() => {
        this.fetchMentorships(); // Refresh mentorships after editing
        const modal = Modal.getInstance(document.getElementById('editMentorshipModal'));
        modal.hide();
      })
      .catch(error => {
        console.log(error);
        this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا دوباره تلاش کنید.';
      });
    },
    showDeleteMentorshipModal(hashmd5) {
      this.selectedMentorshipHash = hashmd5;
      const modal = new Modal(document.getElementById('deleteMentorshipModal'));
      modal.show();
    },
    confirmDeleteMentorship() {
      const token = localStorage.getItem('jwt');
      axios.post('https://dashboard.ordmenpodcast.com/v/delete_mentorship.php', {
        hashmd5: this.selectedMentorshipHash
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(() => {
        this.fetchMentorships(); // Refresh mentorships after deletion
        const modal = Modal.getInstance(document.getElementById('deleteMentorshipModal'));
        modal.hide();
      })
      .catch(error => {
        this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا دوباره تلاش کنید.';
      });
    },
    toggleSort(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }
      this.sortMentorships();
    },
    sortMentorships() {
      this.filteredMentorships.sort((a, b) => {
        let result = 0;
        if (this.sortKey === 'mentorshipOrder') {
          result = this.mentorshipOrder[a.hashmd5] - this.mentorshipOrder[b.hashmd5];
        } else if (this.sortKey === 'date') {
          result = new Date(a.date) - new Date(b.date);
        }
        return this.sortOrder === 'asc' ? result : -result;
      });
    }
  },
  mounted() {
    this.fetchMentorships();
    this.setDefaultMentorshipMonth(); // Set default month after fetching mentorships
 
  }
};
</script>

<style>

.modal-dialog {
  max-width: 700px;
}

.modal-content {
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
}

.container{
    width: 100% !important;
    max-width: 100% !important;
    font-size:12.5px!important;
  }

.markdown>table, .table {
  --tblr-table-color: inherit;
  --tblr-table-bg: transparent;
  --tblr-table-border-color: var(--tblr-border-color-translucent);
  --tblr-table-accent-bg: transparent;
  --tblr-table-striped-color: inherit;
  --tblr-table-striped-bg: transparent!important;
  --tblr-table-active-color: inherit;
  --tblr-table-active-bg: transparent!important;
  --tblr-table-hover-color: inherit;
  --tblr-table-hover-bg: transparent!important;
  width: 100%;
  margin-bottom: 1rem;
  color: var(--tblr-table-color);
  vertical-align: top;
  border-color: var(--tblr-table-border-color);
}


.icon-tabler-chevron-up,
.icon-tabler-chevron-down {
  transition: transform 0.2s ease;
}

.icon-tabler-chevron-up {
  transform: rotate(0deg);
}

.icon-tabler-chevron-down {
  transform: rotate(180deg);
}


</style>