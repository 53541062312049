import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import CKEditor from '@ckeditor/ckeditor5-vue';
import 'vue-multiselect/dist/vue-multiselect.min.css';

const app = createApp(App);

app.use(router);
app.use(CKEditor);
app.component('DatePicker', Vue3PersianDatetimePicker);
app.mount('#app');
