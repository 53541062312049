<template>
  <div class="container mt-5">
    <h1>تنظیمات</h1>
    <div class="table-responsive">
      <table class="table">
        <tbody>
          <!-- Existing Settings -->
          <tr>
            <td>اعلان خرید وبینار به ایمیل</td>
            <td>
              <input type="checkbox" v-model="settings.webinar_order_email" />
            </td>
          </tr>
          <tr>
            <td>اعلان خرید وبینار به تلگرام</td>
            <td>
              <input type="checkbox" v-model="settings.webinar_order_telegram" />
            </td>
          </tr>
          <tr>
            <td>اعلان خرید مشاوره به ایمیل</td>
            <td>
              <input type="checkbox" v-model="settings.mentorship_order_email" />
            </td>
          </tr>
          <tr>
            <td>اعلان خرید مشاوره به تلگرام</td>
            <td>
              <input type="checkbox" v-model="settings.mentorship_order_telegram" />
            </td>
          </tr>
          <!-- New Settings -->
          <tr>
            <td>اعلان خرید محصول به ایمیل</td>
            <td>
              <input type="checkbox" v-model="settings.product_order_email" />
            </td>
          </tr>
          <tr>
            <td>اعلان خرید محصول به تلگرام</td>
            <td>
              <input type="checkbox" v-model="settings.product_order_telegram" />
            </td>
          </tr>
          <!-- Existing Settings -->
          <tr>
            <td>اعلان تماس یا درخواست به ایمیل</td>
            <td>
              <input type="checkbox" v-model="settings.contact_email" />
            </td>
          </tr>
          <tr>
            <td>اعلان تماس یا درخواست به تلگرام</td>
            <td>
              <input type="checkbox" v-model="settings.contact_telegram" />
            </td>
          </tr>
          <tr>
            <td>Chat ID</td>
            <td>
              <input type="text" v-model="settings.chatId" :placeholder="settings.chatId" class="form-control" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br>
    <button @click="saveSettings" class="btn btn-primaryy">ذخیره تنظیمات</button>

    <!-- Toast -->
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="copyToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">عملیات</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ toastMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Toast } from 'bootstrap';

export default {
  data() {
    return {
      settings: {
        webinar_order_email: false,
        webinar_order_telegram: false,
        mentorship_order_email: false,
        mentorship_order_telegram: false,
        product_order_email: false, // New Setting
        product_order_telegram: false, // New Setting
        contact_email: false,
        contact_telegram: false,
        chatId: ''
      },
      originalSettings: {},
      toastMessage: '',
      columnNames: {
        webinar_order_email: 'اعلان خرید وبینار به ایمیل',
        webinar_order_telegram: 'اعلان خرید وبینار به تلگرام',
        mentorship_order_email: 'اعلان خرید مشاوره به ایمیل',
        mentorship_order_telegram: 'اعلان خرید مشاوره به تلگرام',
        product_order_email: 'اعلان خرید محصول به ایمیل', // New Setting
        product_order_telegram: 'اعلان خرید محصول به تلگرام', // New Setting
        contact_email: 'اعلان تماس یا درخواست به ایمیل',
        contact_telegram: 'اعلان تماس یا درخواست به تلگرام',
        chatId: 'Chat ID'
      }
    };
  },
  methods: {
    fetchSettings() {
      const token = localStorage.getItem('jwt');
      const username = localStorage.getItem('username');
      axios.post('https://dashboard.ordmenpodcast.com/v/get_user.php', { username }, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((response) => {
        this.settings = {
          webinar_order_email: response.data.webinar_order_email === 1,
          webinar_order_telegram: response.data.webinar_order_telegram === 1,
          mentorship_order_email: response.data.mentorship_order_email === 1,
          mentorship_order_telegram: response.data.mentorship_order_telegram === 1,
          product_order_email: response.data.product_order_email === 1, // New Setting
          product_order_telegram: response.data.product_order_telegram === 1, // New Setting
          contact_email: response.data.contact_email === 1,
          contact_telegram: response.data.contact_telegram === 1,
          chatId: response.data.chatId
        };
        this.originalSettings = { ...this.settings };
      })
      .catch((error) => {
        console.error('Error fetching user settings:', error);
        window.location.href = '/logout';
      });
    },
    saveSettings() {
      const token = localStorage.getItem('jwt');
      const username = localStorage.getItem('username');
      const settingsToUpdate = {};

      for (const key in this.settings) {
        if (this.settings[key] !== this.originalSettings[key]) {
          settingsToUpdate[key] = this.settings[key];
        }
      }

      const updatePromises = Object.keys(settingsToUpdate).map((key) => {
        const value = key === 'chatId' ? this.settings[key] : (this.settings[key] === true ? 1 : 0);
        console.log(`Updating ${key} to ${value} for user ${username}`); // Add logging here
        return axios.post('https://dashboard.ordmenpodcast.com/v/change_user.php', {
          username,
          column: key,
          value
        }, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response) => {
          if (response.data.success) {
            this.showToast(`تنظیمات با موفقیت ذخیره شد.`);
          } else {
            console.error(`Error updating setting ${key}:`, response.data.error);
            this.showToast(`خطا در ذخیره تنظیمات ${this.columnNames[key]}.`);
          }
        })
        .catch((error) => {
          console.error(`Error updating setting ${key}:`, error);
          this.showToast(`خطا در ذخیره تنظیمات ${this.columnNames[key]}.`);
        });
      });

      Promise.all(updatePromises)
      .then(() => {
        this.originalSettings = { ...this.settings };
      })
      .catch((error) => {
        console.error('Error updating user settings:', error);
        this.showToast('خطا در ذخیره تنظیمات.');
      });
    },
    showToast(message) {
      this.toastMessage = message;
      const toastElement = document.getElementById('copyToast');
      const toast = new Toast(toastElement);
      toast.show();
    }
  },
  mounted() {
    this.fetchSettings();
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
}
</style>
