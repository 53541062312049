<template>
  <div id="app" :class="{ 'sidebar-collapsed': !sidebarOpen }">
    <!-- Top Navbar -->
    <nav class="navbar navbar-light navbar-expand-lg fixed-top">
      <div class="container-fluid">

        <!-- Navbar Toggle Button -->
        <button class="navbar-toggler" type="button" @click="toggleSidebar">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <line x1="4" y1="6" x2="20" y2="6" />
            <line x1="4" y1="12" x2="20" y2="12" />
            <line x1="4" y1="18" x2="20" y2="18" />
          </svg>
        </button>

        <!-- Navbar Brand -->
        <a class="navbar-brand" href="#">
          <img src="/dist/img/ordlogo.png" width="155" height="38" alt="Your Logo">
        </a>

        <!-- Navbar Links -->
        <div class="collapse navbar-collapse" id="navbar-menu">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            
            
            <li v-if="isLoggedIn" class="nav-item">
              <router-link to="/dashboard" @click="refreshPage('/dashboard')" class="nav-link" active-class="active-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-dashboard" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="13" r="2" />
                    <line x1="13.45" y1="11.55" x2="15.5" y2="9.5" />
                    <path d="M6.4 20a9 9 0 1 1 11.2 0z" />
                  </svg>
                </span>
                <span class="nav-link-title">داشبورد</span>
              </router-link>
            </li>

            <li v-if="isLoggedIn" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbar-financial">
                مالی
              </a>
              <div class="dropdown-menu" aria-labelledby="navbar-financial">
                <router-link to="/transactions" class="dropdown-item">تراکنش‌ها</router-link>
                <router-link to="/donation_transactions" class="dropdown-item">تراکنش‌های حمایت مالی</router-link>
                <router-link to="/wallets" class="dropdown-item">کیف پول</router-link>
                <router-link to="/discounts" class="dropdown-item">تخفیف‌ها</router-link>
                <router-link to="/accounting" class="dropdown-item">حسابداری</router-link>
              </div>
            </li>

            <li v-if="isLoggedIn" class="nav-item dropdown">
  <a class="nav-link dropdown-toggle" href="#" id="navbar-maamoolijat">
    معمولی‌جات
  </a>
  <div class="dropdown-menu" aria-labelledby="navbar-maamoolijat">
    <router-link to="/webinars" @click="refreshPage('/webinars')" class="dropdown-item">
      <span class="nav-link-icon d-md-none d-lg-inline-block">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-video" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <rect x="3" y="6" width="18" height="12" rx="2" />
          <path d="M10 15l5 -3l-5 -3z" />
        </svg>
      </span>
      <span class="nav-link-title">وبینارها/دوره‌ها</span>
    </router-link>
    <router-link to="/mentorships" @click="refreshPage('/mentorships')" class="dropdown-item">
      <span class="nav-link-icon d-md-none d-lg-inline-block">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="9" cy="7" r="4" />
          <path d="M17 11v2a4 4 0 0 1 -4 4h-6a4 4 0 0 1 -4 -4v-2" />
          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          <path d="M21 17.31v-2a4 4 0 0 0 -4 -4h-1" />
        </svg>
      </span>
      <span class="nav-link-title">مشاوره‌ها</span>
    </router-link>

    <router-link to="/product_transactions" class="dropdown-item"><span class="nav-link-icon d-md-none d-lg-inline-block">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="9" cy="7" r="4" />
          <path d="M17 11v2a4 4 0 0 1 -4 4h-6a4 4 0 0 1 -4 -4v-2" />
          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          <path d="M21 17.31v-2a4 4 0 0 0 -4 -4h-1" />
        </svg>
      </span>محصولات</router-link>

  </div>
</li>
<!-- 
            <li v-if="isLoggedIn" class="nav-item">
              <router-link to="/contacts" @click="refreshPage('/contacts')" class="nav-link" active-class="active-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-robot" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="8" r="4" />
                    <line x1="8" y1="13" x2="8" y2="21" />
                    <line x1="16" y1="13" x2="16" y2="21" />
                    <line x1="9" y1="9" x2="9.01" y2="9" />
                    <line x1="15" y1="9" x2="15.01" y2="9" />
                    <path d="M7 17h10" />
                  </svg>
                </span>
                <span class="nav-link-title">تماس‌ها/درخواست‌ها</span>
              </router-link>
            </li> -->


            <li v-if="isLoggedIn" class="nav-item">
              <router-link to="/messages" @click="refreshPage('/messages')" class="nav-link" active-class="active-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-robot" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="8" r="4" />
                    <line x1="8" y1="13" x2="8" y2="21" />
                    <line x1="16" y1="13" x2="16" y2="21" />
                    <line x1="9" y1="9" x2="9.01" y2="9" />
                    <line x1="15" y1="9" x2="15.01" y2="9" />
                    <path d="M7 17h10" />
                  </svg>
                </span>
                <span class="nav-link-title">پیام‌ها</span>
              </router-link>
            </li>

            <li v-if="isLoggedIn" class="nav-item">
              <router-link to="/robotusers" @click="refreshPage('/robotusers')" class="nav-link" active-class="active-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-robot" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="8" r="4" />
                    <line x1="8" y1="13" x2="8" y2="21" />
                    <line x1="16" y1="13" x2="16" y2="21" />
                    <line x1="9" y1="9" x2="9.01" y2="9" />
                    <line x1="15" y1="9" x2="15.01" y2="9" />
                    <path d="M7 17h10" />
                  </svg>
                </span>
                <span class="nav-link-title">کاربران</span>
              </router-link>
            </li>

            <li v-if="isLoggedIn" class="nav-item">
              <router-link to="/actions" @click="refreshPage('/actions')" class="nav-link" active-class="active-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-robot" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="8" r="4" />
                    <line x1="8" y1="13" x2="8" y2="21" />
                    <line x1="16" y1="13" x2="16" y2="21" />
                    <line x1="9" y1="9" x2="9.01" y2="9" />
                    <line x1="15" y1="9" x2="15.01" y2="9" />
                    <path d="M7 17h10" />
                  </svg>
                </span>
                <span class="nav-link-title">بازدیدها</span>
              </router-link>
            </li>

<!-- 
            <li v-if="isLoggedIn" class="nav-item">
              <router-link to="/mentorshipquestions" @click="refreshPage('/mentorshipquestions')" class="nav-link" active-class="active-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-robot" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="8" r="4" />
                    <line x1="8" y1="13" x2="8" y2="21" />
                    <line x1="16" y1="13" x2="16" y2="21" />
                    <line x1="9" y1="9" x2="9.01" y2="9" />
                    <line x1="15" y1="9" x2="15.01" y2="9" />
                    <path d="M7 17h10" />
                  </svg>
                </span>
                <span class="nav-link-title">سوالات ربات مشاوره</span>
              </router-link>
            </li>



            <li v-if="isLoggedIn" class="nav-item">
              <router-link to="/mentorshipanswers" @click="refreshPage('/mentorshipanswers')" class="nav-link" active-class="active-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-robot" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="8" r="4" />
                    <line x1="8" y1="13" x2="8" y2="21" />
                    <line x1="16" y1="13" x2="16" y2="21" />
                    <line x1="9" y1="9" x2="9.01" y2="9" />
                    <line x1="15" y1="9" x2="15.01" y2="9" />
                    <path d="M7 17h10" />
                  </svg>
                </span>
                <span class="nav-link-title">پاسخ‌های ربات مشاوره</span>
              </router-link>
            </li> -->


            <li v-if="isLoggedIn" class="nav-item">
              <router-link to="/marketing" @click="refreshPage('/marketing')" class="nav-link" active-class="active-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-robot" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="8" r="4" />
                    <line x1="8" y1="13" x2="8" y2="21" />
                    <line x1="16" y1="13" x2="16" y2="21" />
                    <line x1="9" y1="9" x2="9.01" y2="9" />
                    <line x1="15" y1="9" x2="15.01" y2="9" />
                    <path d="M7 17h10" />
                  </svg>
                </span>
                <span class="nav-link-title">مارکتینگ</span>
              </router-link>
            </li>


            <li v-if="isLoggedIn" class="nav-item">
              <router-link to="/charts" @click="refreshPage('/charts')" class="nav-link" active-class="active-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-bar" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <rect x="3" y="12" width="6" height="8" rx="1" />
                    <rect x="9" y="8" width="6" height="12" rx="1" />
                    <rect x="15" y="4" width="6" height="16" rx="1" />
                    <line x1="4" y1="20" x2="18" y2="20" />
                  </svg>
                </span>
                <span class="nav-link-title">نمودارهای تحلیلی</span>
              </router-link>
            </li>



            <!-- Other nav items here -->
          </ul>

          <!-- Account Options and Notifications -->
          <div class="d-flex align-items-center">

            <div class="nav-item dropdown me-3" @mouseover="showAccountOptions = true" @mouseleave="showAccountOptions = false">
  <a href="#" class="nav-link px-0">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 7a4 4 0 1 1 -4 4a4 4 0 0 1 4 -4zm0 2a2 2 0 1 1 -2 2a2 2 0 0 1 2 -2zm0 8c-4.418 0 -8 1.79 -8 4v2h16v-2c0 -2.21 -3.582 -4 -8 -4zm0 2c3.314 0 6 1.343 6 2h-12c0 -0.657 2.686 -2 6 -2z"></path>
    </svg>
  </a>
  <div class="dropdown-menu dropdown-menu-end" style="border: none !important; box-shadow: none; margin-top: 5px;" :class="{ show: showAccountOptions }">
    <div class="card dropdownCard">
      <div class="card-header">
        <h3 class="card-title">{{ getFullName() }}</h3>
      </div>
      <div class="list-group list-group-flush list-group-hoverable">
        <router-link to="/setting" @click="refreshPage('/setting')" class="list-group-item">تنظیمات کاربری</router-link>
        <router-link to="/logout" class="list-group-item">خروج</router-link>
      </div>
    </div>
  </div>
</div>

<div class="nav-item dropdown" style="margin-left:150px;" @mouseover="showNotifications = true" @mouseleave="showNotifications = false">
  <a href="#" class="nav-link px-0">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
      <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
    </svg>
    <span class="badge bg-red" v-if="notifications.length > 0">{{ notifications.length }}</span>
  </a>
  <div class="dropdown-menu dropdown-menu-end" style="border: none !important; box-shadow: none; margin-top: 5px;" :class="{ show: showNotifications }">
    <div class="card dropdownCard">
      <div class="card-header">
        <h3 class="card-title">آخرین به‌روزرسانی‌ها</h3>
      </div>
      <div class="list-group list-group-flush list-group-hoverable">
        <div v-if="notifications.length > 0" class="read-button" @click="markAsRead">خواندم</div>
        <div v-for="notification in notifications" :key="notification.id" class="list-group-item">
          <div class="row align-items-center">
            <div class="col-auto">
              <span :class="['status-dot', notification.seen ? '' : 'status-dot-animated', 'bg-red', 'd-block']"></span>
            </div>
            <div class="col text-truncate">
              <router-link v-if="notification.section === 'contact'" to="/contacts" @click="refreshPage('/contacts')" class="text-body d-block">{{ notification.title }}</router-link>
              <router-link v-else-if="notification.section === 'webinar'" to="/transactions" @click="refreshPage('/transactions')" class="text-body d-block">{{ notification.title }}</router-link>
              <router-link v-else-if="notification.section === 'product'" to="/transactions" @click="refreshPage('/transactions')" class="text-body d-block">{{ notification.title }}</router-link>
              <router-link v-else-if="notification.section === 'mentorship'" to="/mentorships" @click="refreshPage('/mentorships')" class="text-body d-block">{{ notification.title }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


          </div>
        </div>
      </div>
    </nav>

    <!-- Page Content -->
    <div class="page-wrapper">
      <router-view/>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      isLoggedIn: false,
      sidebarOpen: true,
      showNotifications: false,
      showAccountOptions: false,
      notifications: []
    };
  },
  mounted() {
    this.checkLoggedIn();
    this.fetchNotifications();
  console.log(this.getFullName()); // Example usage
  },
  methods: {
    markAsRead() {
      const username = localStorage.getItem('username');
      const token = localStorage.getItem('jwt');
      axios.post('https://dashboard.ordmenpodcast.com/v/update_notification.php', { username }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.success) {
          this.notifications = []; // Clear notifications
          this.fetchNotifications(); // Optionally refetch to update the badge count
        }
      })
      .catch(error => {
        console.error('Error marking notifications as read:', error);
      });
    },
  getFullName() {
    return localStorage.getItem('name');
  },
  checkLoggedIn() {
    const jwtToken = localStorage.getItem('jwt');
    this.isLoggedIn = !!jwtToken;
  },
  toggleNotifications() {
    this.showNotifications = !this.showNotifications;
    if (this.showNotifications) {
      this.fetchNotifications(); // Fetch notifications when the panel is shown
      document.addEventListener('click', this.closeNotifications);
    } else {
      document.removeEventListener('click', this.closeNotifications);
    }
  },
  closeNotifications(event) {
    if (!this.$el.contains(event.target)) {
      this.showNotifications = false;
      document.removeEventListener('click', this.closeNotifications);
    }
  },
    toggleAccountOptions() {
      this.showAccountOptions = !this.showAccountOptions;
      if (this.showAccountOptions) {
        document.addEventListener('click', this.closeAccountOptions);
      } else {
        document.removeEventListener('click', this.closeAccountOptions);
      }
    },
    closeAccountOptions(event) {
      if (!this.$el.contains(event.target)) {
        this.showAccountOptions = false;
        document.removeEventListener('click', this.closeAccountOptions);
      }
    },
  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  },
  fetchNotifications() {
    const username = localStorage.getItem('username');
    const token = localStorage.getItem('jwt');
    axios.post('https://dashboard.ordmenpodcast.com/v/get_notification.php', { username }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      this.notifications = Array.isArray(response.data) ? response.data : [];
    })
    .catch(error => {
      console.error('Error fetching notifications:', error);
    });
  },
  refreshPage(path) {
    if (this.$route.path === path) {
      this.$router.go(0);
    } else {
      this.$router.push(path);
    }
  }
},


  watch: {
    '$route'() {
      this.checkLoggedIn();
    }
  }
};
</script>

<style>


.navbar-vertical.navbar-left {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 220px;
  transition: all 0.3s ease;
  overflow-x: hidden;
}

.navbar-vertical.navbar-left.collapsed {
  width: 60px;
}


.sidebar-toggle {
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  color: inherit;
  font-size: 1.5em;
}





/* Ensure the button is left-aligned */
.nav-item.dropdown {
  position: relative; /* Ensure dropdown menu is positioned relative to this container */
  float:left;
}

.nav-link.px-0 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 0 !important;
}

/* Align the dropdown menu to the left of its parent */
.dropdown-menu-left {
  left: 0 !important; /* Ensure the dropdown aligns to the left of its container */
  right: auto !important; /* Disable any right alignment */
  position: absolute; /* Position it absolutely within the relative container */
  margin-top: 20px !important; /* Adjust vertical spacing if needed */
}

/* Other existing styles */
.page-wrapper {
  display: flex;
  justify-content: flex-start;
}

.dropdown-menu.show {
  display: block !important;
}

/* Hide text when sidebar is collapsed */
.navbar-vertical.navbar-left.collapsed .nav-link-title {
  display: none;
}

/* Center icons when sidebar is collapsed */
.navbar-vertical.navbar-left.collapsed .nav-link-icon {
  margin: 0 auto;
}

/* Adjust toggle button when sidebar is collapsed */
.navbar-vertical.navbar-left.collapsed .sidebar-toggle {
  text-align: center;
}


.active-link .nav-link-icon svg {
  stroke: black;
}

.navbar-vertical.navbar-left {
  display: block !important;
}

.dropdown-menu-arrow:before{
  right: auto;
  left:.75rem;
}



/* Adjust the padding for the "خواندم" button */
.read-button {
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  background-color: #f0f0f0;
  cursor: pointer;
}

.read-button:hover{
  background-color:#EEE;
}

/* For desktop navigation hover behavior */
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
}

.dropdown-menu {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Optional: To ensure dropdown closes smoothly */
.nav-item.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Show below the parent */
  left: 0;
  z-index: 1000;
  background-color: white; /* Adjust based on your theme */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
}

/* Adjust dropdown menu position */
.nav-item.dropdown .dropdown-menu {
  left: -50px; /* Move submenu 20px to the left */
  top: 100%; /* Ensure it appears below the parent menu item */
  position: absolute;
  z-index: 1000;
  background-color: white; /* Adjust based on your theme */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* For desktop navigation hover behavior */
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
}

.dropdown-menu {
  display: none;
  opacity: 0;
  visibility: hidden;
}


.navbar {
  background-color: #fff; /* Ensure the navbar has a background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  z-index: 1000; /* Ensure the navbar stays on top of the content */
  top: 0;
  left: 0;
  width: 100%;
}

/* Page Wrapper */
.page-wrapper {
  padding-top: 6%; /* Add padding equivalent to the navbar height */
  display: flex;
  justify-content: flex-start;
}

.navbar-nav .nav-item{
  
  margin-right: 5px;
}

</style>
