<template>
  <div class="container mt-4">
    <div v-if="showMessagesTable">
    
      <h2>پیام‌ها</h2>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>شناسه</th>
            <th>ایمیل</th>
            <th>عنوان</th>
            <th>نویسنده</th>
            <th>تاریخ</th>
            <th>زمان</th>
            <th>پاسخ کاربر</th>
            <th>تعداد پیام‌های خوانده نشده</th>
          </tr>
        </thead>
        <tbody>
          <tr style="cursor: pointer;" v-for="message in messages" :key="message.id" :class="{ 'done-message': message.done == 1 }"  @click="viewMessageHistory(message)">
            <td>{{ message.id }}</td>
            <td>{{ message.email }}</td>
            <td>{{ message.title }}</td>
            <td>{{ message.author }}</td>
            <td>{{ formatShamsiDate(message.created_at).date }}</td>
            <td>{{ formatShamsiDate(message.created_at).time }}</td>
            <td>{{ hasUserReply(message) ? '✓' : 'X' }}</td>
            <td>
              <div :style="countUnreadMessages(message) > 0 ? { background: 'red', 'border-radius': '7px', padding: '3px 8px', color: '#FFF', float: 'right' } : {}">
                {{ countUnreadMessages(message) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="showMessageHistory" class="message-container" style="margin-top: -15px;">
  

      <div class="header">
    <button @click="goBackToMessages">بازگشت</button>
    <span>
      <strong>عنوان پیام:</strong> {{ primaryMessage.title }}<br>
      <strong>شناسه:</strong> {{ primaryMessage.id }}
    </span>
  </div>

      <div v-for="(message, index) in messageHistory" :key="message.id" class="message-box my-2">
        <div class="message-header">
          <img :src="message.author === 'ادمین' ? 'https://thumbs.dreamstime.com/b/man-customer-care-service-support-icon-vector-flat-color-pictogram-illustration-person-profile-human-avatar-call-centre-98002818.jpg' : 'https://media.istockphoto.com/id/1223671392/vector/default-profile-picture-avatar-photo-placeholder-vector-illustration.jpg?s=612x612&w=0&k=20&c=s0aTdmT5aU6b8ot7VKm11DeID6NctRCpB755rA1BIP0='" alt="User Image" />
          <div class="message-info">
            <span class="name">{{ message.author }}</span>
            <span class="date">{{ formatShamsiDate(message.created_at).date }} | {{ formatShamsiDate(message.created_at).time }}</span>
          </div>
        </div>
        <div class="message-body">
          <p v-html="message.content"></p>

        </div>

        <div style="float:right; width:100%; margin-top: 0px;">
         
          <button v-if="index == 0 && message.title.includes('نقد کردن کیف پول') && message.done != 1" class="btn btn-warning btn-sm" style="margin-right:5px;margin-bottom:5px;" @click="confirmWalletUpdate(message)">
            کسر از کیف پول
          </button>

          <button v-if="index == 0 && message.done != 1" class="btn btn-success btn-sm" style="margin-right:5px;margin-bottom:5px;" @click="confirmCompletion(message)">
            تکمیل
          </button>
        </div>

      </div>


  <!-- Send Response Section (consistently visible) -->
  <div class="reply-container mt-4">
    <div class="reply-header">ارسال پاسخ</div>
    <div class="reply-instructions">
      برای ارسال پاسخ به این پیام، از فرم زیر استفاده کنید.
      
    </div>
    <div class="reply-textarea mt-3">
      <ckeditor :editor="editor" v-model="replyText" :config="editorConfig"></ckeditor>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <button class="btn btn-info reply-button" @click="sendReply">ارسال پاسخ</button>
    </div>
  </div>
    </div>

    <!-- Subtract Amount Modal -->
    <div
      class="modal fade"
      id="subtractAmountModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="subtractAmountModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="subtractAmountModalLabel">کسر از کیف پول</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>مقدار کنونی کیف پول ریال: {{ iranWalletValue }}</p>
            <p>مقدار کنونی کیف پول یورو: {{ euroWalletValue }}</p>
            <div class="form-group">
              <label for="walletType">انتخاب کیف پول:</label>
              <select v-model="selectedWallet" class="form-control">
                <option value="IranWallet">کیف پول ریال</option>
                <option value="EuroWallet">کیف پول یورو</option>
              </select>
            </div>
            <div class="form-group mt-3">
              <label for="subtractAmount">مبلغ:</label>
              <input type="number" v-model="subtractAmount" class="form-control" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              انصراف
            </button>
            <button type="button" class="btn btn-info" @click="showConfirmModal">
              ادامه
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirm Wallet Update Modal -->
    <div
      class="modal fade"
      id="confirmWalletUpdateModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmWalletUpdateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmWalletUpdateModalLabel">تایید کسر از کیف پول</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>کیف پول: {{ selectedWallet === 'IranWallet' ? 'کیف پول ریال' : 'کیف پول یورو' }}</p>
            <p>مقدار کنونی: {{ selectedWallet === 'IranWallet' ? iranWalletValue : euroWalletValue }}</p>
            <p>مقدار جدید: {{ updatedWalletValue }}</p>
            <p>آیا مطمئن هستید که می‌خواهید این مقدار را کسر کنید؟</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              انصراف
            </button>
            <button
              type="button"
              class="btn btn-info"
              @click="updateWallet"
            >
              تایید
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- Toast -->
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="copyToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">عملیات</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ toastMessage }}
        </div>
      </div>
    </div>
    <!-- Confirm Completion Modal -->
    <div
      class="modal fade"
      id="confirmCompletionModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmCompletionModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmCompletionModalLabel">تایید تکمیل</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>آیا مطمئن هستید که می‌خواهید این پیام را تکمیل کنید؟</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              انصراف
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="completeMessage"
            >
              تکمیل
            </button>
          </div>
        </div>
      </div>
    </div>
    <br><br><br><br>
  </div>
</template>

<script>
import axios from 'axios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Modal, Toast } from 'bootstrap';
import { htmlToText } from 'html-to-text';

export default {
  data() {
    return {
      messages: [],
      allMessages: [],
      messageHistory: [],
      showMessagesTable: true,
      showMessageHistory: false,
      selectedWallet: '',
      subtractAmount: 0,
      iranWalletValue: 0,
      replyText: '',  // Global reply text for the response section
      euroWalletValue: 0,
      updatedWalletValue: 0,
      selectedMessage: null,
      editor: ClassicEditor,
      editorConfig: {
        language: 'ar',
        contentsLangDirection: 'rtl',
      },
    };
  },
  computed: {
  primaryMessage() {
    return this.messageHistory[0] || {};
  },
},
  methods: {
    
    toggleReplyBox(message) {
      message.showReplyBox = !message.showReplyBox;
    },
    fetchMessages() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/list_messages.php', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.allMessages = response.data;

          // Filter messages: Include messages if the author is not "ادمین", or if the message has replies.
          this.messages = this.allMessages.filter((message) => {
          const hasReplies = this.allMessages.some(
            (msg) => msg.replied_to_message_id === message.message_id
          );
          
          // Ensure message is a primary message and either not from "ادمین" or has replies
          return message.primary_message === '1' && (message.author !== 'ادمین' || hasReplies);
        });

    })
    .catch((error) => {
      console.error('Error fetching messages:', error);
      
        window.location.href = '/logout';
      
    });
},
hasUserReply(message) {
  let history = [message]; // Start with the primary message

  const collectReplies = (messageId) => {
    const replies = this.allMessages
      .filter((msg) => msg.replied_to_message_id === messageId)
      // Sort replies by created_at in ascending order (earliest first)
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

    replies.forEach((reply) => {
      history.push(reply); // Add reply to the history
      collectReplies(reply.message_id); // Recursively collect replies
    });
  };

  // Collect replies starting from the given message
  collectReplies(message.message_id);

  // If there are replies in the history, check the last reply
  if (history.length > 1) {
    const latestReply = history[history.length - 1];
    return latestReply.author !== 'ادمین'; // Return true if the last reply is not from "ادمین"
  }

  // If no replies exist, return false (show "X")
  return false;
},


    formatShamsiDate(dateTimeString) {
      const [time, date] = dateTimeString.split(' - ');
      return { date, time };
    },
    viewMessageHistory(primaryMessage) {
      
  const token = localStorage.getItem('jwt');
  axios
    .get('https://dashboard.ordmenpodcast.com/v/list_messages.php', {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const allMessages = response.data;

      let history = [primaryMessage];
      const collectReplies = (messageId) => {
        const replies = allMessages
          .filter((msg) => msg.replied_to_message_id === messageId)
          .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

        replies.forEach((reply) => {
          reply.showReplyBox = false;
          reply.replyText = '';
          history.push(reply);
          collectReplies(reply.message_id);
        });
      };

      collectReplies(primaryMessage.message_id);
      this.messageHistory = history;
      this.showMessagesTable = false;
      this.showMessageHistory = true;

      // Mark each message in the history as read
      history.forEach((message) => {
        axios
          .post(
            'https://dashboard.ordmenpodcast.com/v/mark_as_read.php',
            { message_id: message.message_id },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            console.log(`Message ${message.message_id} marked as read`, response);
          })
          .catch((error) => {
            console.error(`Error marking message ${message.message_id} as read:`, error);
          });
      });
    })
    .catch((error) => {
      console.error('Error fetching message history:', error);
    });
},

countUnreadMessages(primaryMessage) {
    let unreadCount = 0;

    // Collect all replies and the primary message
    const history = [primaryMessage];
    const collectReplies = (messageId) => {
      const replies = this.allMessages.filter(
        (msg) => msg.replied_to_message_id === messageId
      );
      replies.forEach((reply) => {
        history.push(reply);
        collectReplies(reply.message_id); // Recursively collect all replies
      });
    };

    // Collect the full history for this message
    collectReplies(primaryMessage.message_id);

    // Count unread messages (mark_as_read == 0)
    history.forEach((msg) => {
      if (msg.mark_as_read == 0) {
        unreadCount++;
      }
    });

    return unreadCount;
  },

    cancelReply(message) {
  message.showReplyBox = false;
},
goBackToMessages() {
  this.showMessageHistory = false;
  this.showMessagesTable = true;
  this.fetchMessages(); // Refresh the table after going back
},
    confirmWalletUpdate(message) {
      this.selectedMessage = message;
      const token = localStorage.getItem('jwt');

      axios
        .post(
          'https://dashboard.ordmenpodcast.com/v/get_dashboard.php',
          {
            chatId: message.chatID,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.iranWalletValue = parseInt(response.data['IranWallet'], 10);
          this.euroWalletValue = parseInt(response.data['EuroWallet'], 10);

          const modalElement = document.getElementById('subtractAmountModal');
          this.confirmWalletUpdateModalInstance = new Modal(modalElement);
          this.confirmWalletUpdateModalInstance.show();
        })
        .catch((error) => {
          console.error('Error fetching wallet data:', error);
        });
    },
    showConfirmModal() {
      const selectedWalletValue =
        this.selectedWallet === 'IranWallet'
          ? this.iranWalletValue
          : this.euroWalletValue;

      this.updatedWalletValue = selectedWalletValue - this.subtractAmount;

      const subtractModalElement = document.getElementById('subtractAmountModal');
      const subtractModalInstance = Modal.getInstance(subtractModalElement);
      subtractModalInstance.hide();

      const modalElement = document.getElementById('confirmWalletUpdateModal');
      this.confirmWalletUpdateModalInstance = new Modal(modalElement);
      this.confirmWalletUpdateModalInstance.show();
    },
    updateWallet() {
      const token = localStorage.getItem('jwt');
      axios
        .post(
          'https://dashboard.ordmenpodcast.com/v/change_dashboard.php',
          {
            chatId: this.selectedMessage.chatID,
            column: this.selectedWallet,
            value: this.updatedWalletValue,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            this.confirmWalletUpdateModalInstance.hide();
            this.fetchMessages();
            this.showToast(
              `مقدار کسر شده از ${
                this.selectedWallet === 'IranWallet' ? 'کیف پول ریال' : 'کیف پول یورو'
              } با موفقیت انجام شد.`
            );
          } else {
            console.error('Error updating wallet:', response.data.error);
          }
        })
        .catch((error) => {
          console.error('Error updating wallet:', error);
        });
    },
    confirmCompletion(message) {
      this.selectedMessage = message;
      const modalElement = document.getElementById('confirmCompletionModal');
      this.confirmModalInstance = new Modal(modalElement);
      this.confirmModalInstance.show();
    },
    async completeMessage() {
      const token = localStorage.getItem('jwt');

      try {
        console.log(this.selectedMessage.id);
        // Mark the message as done
        const messageResponse = await axios.post(
          'https://dashboard.ordmenpodcast.com/v/change_message.php',
          {
            id: this.selectedMessage.id,
            column: 'done',
            value: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (messageResponse.data.success) {
          this.selectedMessage.done = 1;
          this.confirmModalInstance.hide();
          this.fetchMessages(); // Fetch the updated messages
          this.showToast('پیام با موفقیت تکمیل شد.');
        } else {
          console.error('Error updating message:', messageResponse.data.error);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    
    

  sendReply() {
      const token = localStorage.getItem('jwt');
      const telegramMessage = 
  htmlToText(this.replyText, {
    wordwrap: 130,
    uppercaseHeadings: false,
    singleNewLineParagraphs: true,
    tags: {
      a: { format: 'inline' },
      p: { format: 'block' }
    }
  }).trim() // Trim extra spaces or newlines from start and end
;

      const email = this.primaryMessage.email;
      const lastMessageId = this.messageHistory[this.messageHistory.length - 1].message_id; // Get the ID of the last message
      
      axios
        .post(
          'https://dashboard.ordmenpodcast.com/v/send_telegram_using_email_message.php',
          {
            email: email,
            text: telegramMessage,
            replied_to_message_id: lastMessageId,  // Reference to the last message
            primary_message: 0,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log('Reply sent successfully:', response.data);
          this.replyText = ''; // Clear the reply box after sending
          console.log(this.primaryMessage);
          this.fetchUpdatedMessageHistory(this.primaryMessage.message_id); // Refresh message history
        })
        .catch((error) => {
          console.error('Error sending reply:', error);
        });
    },
fetchUpdatedMessageHistory(primaryMessageId) {
  const token = localStorage.getItem('jwt');
  axios
    .get('https://dashboard.ordmenpodcast.com/v/list_messages.php', {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const allMessages = response.data;

      // Start the history with the primary message
      const primaryMessage = allMessages.find(msg => msg.message_id === primaryMessageId);
      let history = [primaryMessage];

      const collectReplies = (msgId) => {
        const replies = allMessages.filter(msg => msg.replied_to_message_id === msgId);
        replies.sort((a, b) => a.message_id - b.message_id);
        replies.forEach((reply) => {
          history.push(reply);
          collectReplies(reply.message_id); // Recursively collect all replies
        });
      };

      collectReplies(primaryMessage.message_id);
      this.messageHistory = history; // Update the displayed history
    })
    .catch((error) => {
      console.error('Error fetching updated message history:', error);
    });
},
    
    showToast(message) {
      this.toastMessage = message;
      const toastElement = document.getElementById('copyToast');
      const toast = new Toast(toastElement);
      toast.show();
    },
  },
  mounted() {
    this.fetchMessages();
  },
};
</script>

<style scoped>
.container {
  width: 100% !important;
  max-width: 100% !important;
  font-size: 12.5px !important;
  margin-top: 20px;
}
.table {
  margin-top: 20px;
}

.card {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
}

.btn-info {
  color: white;
}

.btn-success{
  margin-top: 0;
    margin-bottom: 0;
}

.done-message {
  opacity:0.3;
}

.container {
  width: 80% !important;
  max-width: 100% !important;
  background-color: #EEE;
  
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
}
.header {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 10px;
  background-color: #FFF;
  border-bottom: 2px solid #ddd;
  font-size: 1.1rem;
  border-radius: 5px 5px 0 0;
  color: #333;
  font-weight: bold;
  margin-top:20px;
}
.message-container {
  margin-top: 0;
}
.message-box {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.message-header {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 8px 8px 0 0;
}
.message-header img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
}
.message-info {
  flex-grow: 1;
  color: #555;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
}
.message-info .name {
  font-weight: bold;
  color: #333;
}
.message-info .date {
  font-size: 0.8rem;
  color: #888;
}
.message-body {
  padding: 25px 60px;
  line-height: 1.6;
}
.done-message {
  background-color: #f0fff0 !important;
}
.btn-info {
  background-color: #0056b3;
  color: white;
}


.header button {
      background-color: #0056b3;
      color: white;
      border: none;
      padding: 5px 15px;
      border-radius: 4px;
      font-size: 0.9rem;
      cursor: pointer;
    }
	.header span{
		float:right;
		font-weight: normal;
		margin-right:15px;
	}
    .message-container {
      margin-top: 0;
    }
    .message-box {
      background-color: #f9f9f9;
      border-radius: 0;
      padding: 0;
      margin-bottom: 0;
      box-shadow: 0;
    }
    .message-header {
      display: flex;
      align-items: center;
      background-color: #e0e0e0;
      padding: 10px;
      border-radius: 0;
    }
    .message-header img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-left: 10px;
    }
    .message-info {
      flex-grow: 1;
      color: #555;
      font-size: 0.9rem;
      display: flex;
      flex-direction: column;
    }
    .message-info .name {
      font-weight: bold;
      color: #333;
    }
    .message-info .date {
      font-size: 0.8rem;
      color: #888;
    }
    .message-body {
	
    padding: 25px 60px;
    line-height: 1.6;
    }
    code {
      background-color: #e8e8e8;
      padding: 2px 5px;
      border-radius: 4px;
      font-family: monospace;
    }
    .reply-container {
      background-color: #f9f9f9;
      border-radius: 8px;
      padding: 15px;
      margin-top: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    .reply-header {
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }
    .reply-instructions {
      background-color: #e8f5e9;
      padding: 10px;
      border-radius: 5px;
      font-size: 0.9rem;
      color: #388e3c;
    }
    .reply-textarea {
      margin-top: 10px;
    }
    .reply-button {
      background-color: #0056b3;
      color: white;
      border: none;
      padding: 8px 20px;
      border-radius: 4px;
      font-size: 1rem;
      cursor: pointer;
    }
</style>
