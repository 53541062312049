<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="card logincard mt-5" id="logincard" style="padding-right:40px;">
          <div class="row">
            <div class="col-md-5" style="padding-top:50px;padding-bottom:50px;">
              <div class="card-body">
                <h3 class="card-title text-right IRANSansXFaNum">ورود به داشبورد</h3>
                <p class="fifteen">
                  برای استفاده از خدمات ‌پادکست، وارد حساب کاربری خود شوید.<br>
                  در صورت نداشتن حساب کاربری ثبت نام کنید
                </p>
                <form @submit.prevent="login">
                  <div class="mb-3">
                    <input type="text" class="form-control form-control-login" placeholder="نام کاربری" id="username" v-model="username" required>
                  </div>
                  <div class="mb-3">
                    <input type="password" class="form-control form-control-login" placeholder="رمز عبور" id="password" v-model="password" required>
                  </div>
                  <button type="submit" class="btn-primary float-end">ورود</button>
                </form>
                <div v-if="error" style="margin-top:10px;float:right;font-weight:bold;color:red;">
                  {{ error }}
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="bgloginleft">
                  
                <div class="text-center img-center">
                  <img src="/dist/img/loginlogo.png"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
      password: '',
      error: null,
    };
  },

  mounted() {
    if (document.getElementById('logincard')) {
      const style = document.createElement('style');
      style.innerHTML = `
        .navbar-vertical.navbar-left {
          display: none !important;
        }
        .notifBar{
        display:none!important;
      }
      `;
      document.head.appendChild(style);
    }
  },
  methods: {
    async login() {
      this.error = null; // Reset error message before attempting login
      try {
        const recaptchaToken = await this.getRecaptchaToken();
        const response = await axios.post('https://dashboard.ordmenpodcast.com/v/authenticate.php', {
          username: this.username,
          password: this.password,
          recaptchaToken: recaptchaToken
        });

            const token = response.data.token;

        if (token) {
          localStorage.setItem('jwt', token);
          localStorage.setItem('username', this.username);
          localStorage.setItem('name', response.data.name);
          this.$router.push('/dashboard');
        } else {
          this.error = response.data.error || 'نام کاربری یا رمز عبور اشتباه است.';
        }
      } catch (err) {
        if (err.response) {
          // Server responded with a status other than 200 range
          this.error = `Error: ${err.response.data.error}`;
        } else if (err.request) {
          // Request was made but no response received
          this.error = 'سرور به مشکل خورده است.';
        } else {
          // Something happened in setting up the request
          this.error = `خطا: ${err.message}`;
        }
      }
    },
    getRecaptchaToken() {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        grecaptcha.ready(() => {
          // eslint-disable-next-line no-undef
          grecaptcha.execute('6LfKZAAqAAAAALN9zx2FBr0ylWfaKcdiCPw7eIU1', { action: 'login' }).then(token => {
            resolve(token);
          }).catch(error => {
            reject(error);
          });
        });
      });
    }
  },
};
</script>

<style>

.logincard{
background: #FFFFFF;
box-shadow: 0px 0px 14.2px 2px rgba(0, 0, 0, 0.25);
border-radius: 20px;
}

.img-center{
  margin-top:35%;
}
.page-wrapper{
  margin-top:0!important;
}
</style>

<style>

.container{
    width: 100% !important;
    max-width: 100% !important;
    font-size:12.5px!important;
  }


.logincard{
background: #FFFFFF;
box-shadow: 0px 0px 14.2px 2px rgba(0, 0, 0, 0.25);
border-radius: 20px;
}

.img-center{
  margin-top:35%;
}
.page-wrapper{
  margin-top:0!important;
}
</style>

<style scoped>

.container{
  padding-top: 30px;
  margin-top:-50px;
}
.eclipse {
  background: url('./static/eclipse.png') left no-repeat;
  height:140px;
  padding-top:20px;
  width:1150px;
  margin-top:-20px;
  margin:0 auto;
}
/* Apply custom styles when autofill is triggered */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px #24272A inset !important;
    box-shadow: none !important;
    border-radius: 49px !important;
    background-color: #24272A !important;
    color: rgba(255, 255, 255, 0.7) !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.7) !important;
    padding: 15px !important;
    transition: background-color 5000s ease-in-out 0s;
}


</style>