<template>
    <div class="container mt-5">
      <!-- Search Input -->
      <div class="col-lg-12" style="margin-bottom:25px;">
        <div class="card" style="padding:10px 15px;border-radius:50px;">
          <div style="float:right;width:100%;">
            <div style="float:left;width:20%">
              <input
                type="text"
                v-model="searchQuery"
                placeholder="جستجو: نام کاربر ..."
                class="form-control form-search"
              />
            </div>
          </div>
        </div>
      </div>
  
      <!-- User Summary Table -->
      <div v-if="!showUserActions" class="col-lg-12 card" style="padding:17px; margin-bottom:15px;">
        <div v-if="filteredUserSummaries.length > 0" class="table-responsive mt-3">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">ردیف</th>
                <th scope="col">نام کاربر</th>
                <th scope="col">آخرین بازدید</th>
                <th scope="col">تاریخ آخرین فعالیت</th>
                <th scope="col">تعداد فعالیت‌های مشاهده‌نشده</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(summary, index) in filteredUserSummaries"
                :key="summary.UserID"
                @click="showActionsForUser(summary.UserID, summary.EMailAddress)"
                style="cursor: pointer;"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ summary.FirstNameLastName }}</td>
                <td>{{ summary.lastAction }}</td>
                <td>{{ summary.lastActionDate }}</td>
                <td>
                    <div :style="summary.unseenCount > 0 ? { background: 'red', 'border-radius': '7px', padding: '3px 8px', color: '#FFF', float: 'right' } : {}">
                {{ summary.unseenCount }}
                        </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
      <!-- User Detailed Actions Table -->
      <div v-if="showUserActions" class="col-lg-12 card" style="padding:17px; margin-bottom:15px;">
        <button @click="handleBackToSummaries" class="btn btn-secondary mb-3">بازگشت</button>
        <div class="table-responsive mt-3">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">ردیف</th>
                <th scope="col">بازدید از</th>
                <th scope="col">تاریخ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(action, index) in userActions" :key="action.ID">
                <td>{{ index + 1 }}</td>
                <td>{{ action.Action }}</td>
                <td>{{ action.created_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="reply-container mt-4">
  <div class="reply-header">ارسال پیام</div>
  <div class="reply-instructions">
    برای ارسال پیام از فرم زیر استفاده کنید.
  </div>
  <div class="reply-textarea mt-3">
    <ckeditor :editor="editor" v-model="messageText" :config="editorConfig"></ckeditor>
  </div>
  <div class="d-flex justify-content-end mt-3">
    <button class="btn btn-info reply-button" @click="sendMessage">ارسال پیام</button>
  </div>
</div>

      </div>
  
      <!-- Error Message -->
      <div v-if="error" class="alert alert-danger mt-3">
        {{ error }}
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { htmlToText } from 'html-to-text';
  export default {
    data() {
      return {
        actions: [],
        error: '',
        searchQuery: '',
        showUserActions: false,
        selectedUserID: null,
        EMailAddress: null,
        userActions: [],
        editor: ClassicEditor,
        editorConfig: {
            language: 'ar',
            contentsLangDirection: 'rtl',
        }
      };
    },
    computed: {
      groupedActions() {
        const grouped = {};
        if (Array.isArray(this.actions)) {
          this.actions.forEach(action => {
            if (!grouped[action.UserID]) {
              grouped[action.UserID] = {
                ...action,
                lastAction: action.Action,
                lastActionDate: action.created_at,
                EMailAddress: action.EMailAddress,
                unseenCount: 0
              };
            }
            // Increment unseen count if mark_as_seen is 0
            if (action.mark_as_seen == 0) {
                
              grouped[action.UserID].unseenCount += 1;
            }
          });
        }
        return Object.values(grouped);
      },
      filteredUserSummaries() {
    const query = this.searchQuery.toLowerCase();
    return this.groupedActions
      .filter(summary => {
        return (
          summary.FirstNameLastName &&
          summary.FirstNameLastName.toLowerCase().includes(query)
        );
      })
      .sort((a, b) => {
        // Extract and parse dates from the format "HH:mm:ss - YYYY-MM-DD"
        const dateA = new Date(a.lastActionDate.split(' - ')[1] + ' ' + a.lastActionDate.split(' - ')[0]);
        const dateB = new Date(b.lastActionDate.split(' - ')[1] + ' ' + b.lastActionDate.split(' - ')[0]);
        return dateB - dateA; // Sort in descending order (most recent first)
      });
  }
    },
    methods: {

    sendMessage() {
      const token = localStorage.getItem('jwt');
      const telegramMessage = htmlToText(this.messageText, {
        wordwrap: 130,
        uppercaseHeadings: false,
        singleNewLineParagraphs: true,
        tags: {
          a: { format: 'inline' },
          p: { format: 'block' }
        }
      }).trim();

      // Assuming the `selectedUserID` is already set when viewing user actions
      const userID = this.selectedEMailAddress; 
      console.log(userID)

      axios
        .post(
          'https://dashboard.ordmenpodcast.com/v/send_telegram_using_email_message.php',
          {
            email: userID,
            text: telegramMessage,
            primary_message: 1,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(response => {
          console.log('Message sent successfully:', response.data);
          this.messageText = ''; // Clear the textarea after sending
        })
        .catch(error => {
          console.error('Error sending message:', error);
        });
    },
    showActionsForUser(userID, EMailAddress) {
    this.selectedUserID = userID;
    this.selectedEMailAddress = EMailAddress;
    this.userActions = this.actions.filter(
      action => action.UserID === userID
    );

    const token = localStorage.getItem('jwt');

    // Iterate over each action and send a request to mark it as read
    this.userActions.forEach(action => {
      axios
        .post(
          'https://dashboard.ordmenpodcast.com/v/mark_as_read_action.php',
          { ID: action.ID }, // Send each action ID as "ID" parameter
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(response => {
          console.log(`Action ${action.ID} marked as read:`, response.data);
        })
        .catch(error => {
          console.error(`Error marking action ${action.ID} as read:`, error);
        });
    });

    this.showUserActions = true;
  },


  handleBackToSummaries() {
    this.showUserActions = false;
    this.fetchActions(); // Refetch the data to get updated summaries
  },

  fetchActions() {
    const token = localStorage.getItem('jwt');
    axios
      .post(
        'https://dashboard.ordmenpodcast.com/v/list_actions.php',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        this.actions = Array.isArray(response.data) ? response.data : [];
      })
      .catch(error => {
        this.error = error.response
          ? error.response.data.error
          : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
      });
  }
    },
    mounted() {
      this.fetchActions();
    }
  };
  </script>
  
  <style>
  
  .message-container {
      margin-top: 0;
    }
    .message-box {
      background-color: #f9f9f9;
      border-radius: 0;
      padding: 0;
      margin-bottom: 0;
      box-shadow: 0;
    }
    .message-header {
      display: flex;
      align-items: center;
      background-color: #e0e0e0;
      padding: 10px;
      border-radius: 0;
    }
    .message-header img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-left: 10px;
    }
    .message-info {
      flex-grow: 1;
      color: #555;
      font-size: 0.9rem;
      display: flex;
      flex-direction: column;
    }
    .message-info .name {
      font-weight: bold;
      color: #333;
    }
    .message-info .date {
      font-size: 0.8rem;
      color: #888;
    }
    .message-body {
	
    padding: 25px 60px;
    line-height: 1.6;
    }
    code {
      background-color: #e8e8e8;
      padding: 2px 5px;
      border-radius: 4px;
      font-family: monospace;
    }
    .reply-container {
      background-color: #f9f9f9;
      border-radius: 8px;
      padding: 15px;
      margin-top: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    .reply-header {
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }
    .reply-instructions {
      background-color: #e8f5e9;
      padding: 10px;
      border-radius: 5px;
      font-size: 0.9rem;
      color: #388e3c;
    }
    .reply-textarea {
      margin-top: 10px;
    }
    .reply-button {
      background-color: #0056b3;
      color: white;
      border: none;
      padding: 8px 20px;
      border-radius: 4px;
      font-size: 1rem;
      cursor: pointer;
    }</style>